.inbox-title-container {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.react-confirm-alert-overlay {
    background: rgba(28, 33, 43, 0.8);
}

.react-confirm-alert-body {
    background: var(--dark-blue);
    color: var(--teal);
}

.react-confirm-alert-button-group .yes-button {
    background-color: var(--teal);
    font-family: "Readex Pro Bold";
    color: var(--dark-blue);
}

.react-confirm-alert-button-group .no-button {
    background-color: var(--orange);
    font-family: "Readex Pro Bold";
    color: var(--dark-blue);
}

.inbox-container .nav-item {
    margin-right: 0.5rem;
}

.inbox-container .nav-item .nav-link {
    outline: 2px solid var(--dark-blue);
    --bs-nav-link-hover-color: var(--teal);
    --bs-nav-link-color: var(--white-teal);
    border-radius: 11px;
}

.inbox-container .nav-item .nav-link:hover {
    background: var(--dark-blue);
    transition: background-color 0.2s ease-in-out;
}   

.inbox-container .tab-pane.show {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

#inbox-tab-row {
    margin-bottom: 1rem;
}
.feed-title-container {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.feed-title-container h1{
    margin-bottom: 0;
}

.homepage .load-more-posts-button {
    width: 40rem;
    border-radius: 2rem;
    padding: 0.5rem 0rem;
    background-color: var(--teal-alt);
    border: none;
    margin-top: 1rem;
}

.homepage .container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.homepage .container .posts {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@font-face {
    font-family: 'Readex Pro';
    src: local('Readex Pro'), url(./des/fonts/ReadexPro-Regular.ttf) format('truetype');
  }
  
  @font-face {
    font-family: 'Readex Pro Light';
    src: local('Readex Pro Light'), url(./des/fonts/ReadexPro-Light.ttf) format('truetype');
  }
  
  @font-face {
    font-family: 'Readex Pro Medium';
    src: local('Readex Pro Medium'), url(./des/fonts/ReadexPro-Medium.ttf) format('truetype');
  }
  
  @font-face {
    font-family: 'Readex Pro Bold';
    src: local('Readex Pro Bold'), url(./des/fonts/ReadexPro-Bold.ttf) format('truetype');
  }

// overwrite theme variables
@import "node_modules/bootstrap/scss/_functions.scss";
@import "node_modules/bootstrap/scss/_variables.scss";
@import "node_modules/bootstrap/scss/_mixins.scss";


$theme-colors: (
    "teal": #BFEFE9,
    "orange": #FFC271,
    "teal-alt": #84c2bb,
    "dark-blue": #242A37,
    "slightly-darker-blue": #1C212B,
    "darker-blue": #1A1C1E,
    "even-darker-blue": #111418,
    "white-teal": #ECFAF8,
    "primary": #BFEFE9,
    "secondary": #FFC271,
    "btn-color": #FFC271,
    "danger": #f5a53d,
    "bg-dark": #242A37,
);

:root {
  --teal: #BFEFE9;
  --orange: #FFC271;
  --teal-alt: #84c2bb;
  --dark-blue: #242A37;
  --slightly-darker-blue: #1C212B;
  --light-dark-blue: #29303e;
  --darker-blue: #191d26;
  --even-darker-blue: #0e1016;
  --white-teal: #ECFAF8;
  --toastify-toast-width: 500px !important;
  --toastify-color-dark: var(--darker-blue) !important;
  --toastify-font-family: "Readex Pro Light" !important;
  --toastify-color-progress-dark: var(--orange) !important;
}

$font-family-sans-serif: system-ui, -apple-system, "Readex Pro" !default;
$font-family-base: "Readex Pro" !important;


@import "node_modules/bootstrap/scss/bootstrap.scss";

.root-container {
  display: flex;
  flex-direction: column;
  background-color: var(--even-darker-blue);
}

.main {
  display: flex;
  flex-direction: row;
  height: 100%;
  min-height: 100vh;
}

//----------------------------------------------//
//*************** Side bar edits ***************//
//----------------------------------------------//
.main #sidebar {
  background-color: var(--dark-blue);
}

.main .nav-pills {
  --bs-nav-pills-link-active-bg: var(--teal-alt);
}


// Move main page (where stream/inbox/explore pages are going to be) to the right...due to issues with sidebar
.main .main-content-container {
  margin-left: 4rem;
  width: 100%;
}



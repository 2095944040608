.like-card {
    width: 35rem;
    height: fit-content;
    margin: 0.2em 0em;
    --bs-card-bg: var(--dark-blue);
}

.like-card .card-body {
    margin-bottom: 0;
    padding: 0;
    color: var(--white-teal);
    background-color: var(--orange);
    box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
    border: transparent;
    border-radius: 11px;
    /* width: auto; */
    height: 3rem;
    display: flex;
    align-items: center;
}

.like-card .card-row {
    height: 4rem;
    width: 100%;
    flex-wrap: nowrap;
}

.like-card .card-header {
    background-color: var(--orange);
}

.like-card .profile-pic {
    width: 3em;
    margin-left: -1em;
}

.like-card .text {
    color: var(--dark-blue);
    font-size: 1em;
    align-items: center;
    width: 100%;
    vertical-align: middle;
    margin-top: 1em;
    margin-bottom: 1em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: small;
}

.like-card .like-card-profile {
    display: flex;
    gap: 0.5em;
    align-items: center;
    margin-bottom: 0;
    max-width: 15rem;
    font-size: small;
}

.like-card .like-card-profile {
    cursor: pointer;
}
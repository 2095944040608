.post-card {
    margin-top: 2rem;
    --bs-card-bg: none;
    border: none;
}

.post-card .card-header {
    width: 40em;
    display: inline-flex;
    align-items: center;
    background-color: var(--slightly-darker-blue);
    box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
    border: 2px solid var(--dark-blue);
}

.post-card .profile-pic {
    width: 3rem;
}

.post-card .post-author {
    display: inline-flex;
    align-items: center;
    gap: 1rem;
}

.post-card .post-author:hover {
    cursor: pointer;
}

.post-card .card-title {
    font-size: 2rem;
    margin-bottom: 3%;
    text-decoration: var(--teal);
    white-space: pre-line;

}

.post-card .card-text {
    padding: 1em;
    width: 100%;
    white-space: pre-line;
    font-family: "Readex Pro Light";
}

.post-card > .card-body {
    color: var(--white-teal);
    background-color: var(--dark-blue);
    box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
    border: transparent;
    border-radius: 0px 0px 11px 11px;
    padding: 2rem;
    width: 40em;
    display: flex;
    flex-direction: column;
}

.post-card .comments-text {
    color: var(--teal);
    margin-top: 0.5em;
    padding-bottom: 2em;
}

.post-card .comments-text p {
    font-size: 0.9rem;
    font-family: "Readex Pro Light";
}

.post-card .unlisted-indicator {
    display: inline-flex;
    align-items: center;
    margin-left: auto;
    margin-right: 1rem;
    gap: 0.5rem;
    background-color: var(--dark-blue);
    border-radius: 2.5rem;
    font-size: 0.9rem;
    font-family: 'Readex Pro Light';
    padding: 0.3rem 1rem;
    color: var(--teal);
}

.friends-indicator, .private-indicator {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    background-color: var(--dark-blue);
    border-radius: 2.5rem;
    font-size: 0.9rem;
    font-family: 'Readex Pro Light';
    color: var(--teal);
}


.post-card .options {
    display: inline-block;
    white-space: nowrap;
}

#dropdown-basic {

    display: inline-block;
    white-space: nowrap;
}

.post-card .dropdown-toggle::after {
    content: none;
}

.options .btn-primary {
    --bs-btn-border-color: transparent;
    --bs-btn-hover-border-color: var(--darker-blue);
    border-radius: 2rem;
    --bs-btn-hover-color: var(--teal);
    --bs-btn-active-border-color: var(--teal);
    --bs-btn-color: var(--white-teal);
}

.options .dropdown-menu {
    background-color: var(--slightly-darker-blue);
    box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
    --bs-dropdown-link-active-bg: var(--teal);
    --bs-dropdown-link-hover-bg: var(--darker-blue);
    --bs-dropdown-link-hover-color: var(--teal);
    --bs-dropdown-link-color: var(--white-teal);
}

.options .dropdown-menu .delete-post {
    --bs-dropdown-link-hover-color: var(--orange);
}

.options .dropdown-item {
    padding: 0.8em var(--bs-dropdown-item-padding-x);
}

.post-card .input-comment {
    width: 100%;
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 11fr 1fr;
}

.post-card .input-comment .form-control {
    background-color: var(--slightly-darker-blue);
    box-shadow: none;
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
}

.post-card .input-comment .form-control:focus {
    background-color: var(--darker-blue);
    box-shadow: 0px 1px var(--teal);
    outline: 5px var(--teal);
    border: 5px var(--teal);
}

.post-card .input-comment .btn-primary {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    font-size: 0.8rem;
}

.post-card .post-image {
    max-width: 100%;
    align-self: center;
}

.post-card .like-comment-container {
    display: flex;
    align-items: center;
    gap: 0.1rem;
}

.post-card .like-comment-container > .like-icon {
    transition-duration: 300ms;
    transition-property: color, transform;
    transition-timing-function: ease-in-out;
    width: 2rem;
    height: auto;
    padding: 0.5rem;
}

.post-card .like-comment-container > .like-icon:hover {
    color: var(--teal) !important;
    cursor: pointer;
    transition: color 0.2s ease-in-out, background-color 0.5s ease-in-out;
    background-color: var(--slightly-darker-blue);
    border-radius: 11px;
}

.post-card .like-comment-container > .like-icon:active {
    color: var(--orange) !important;
    transform: rotate(10deg);
}

.post-card .like-comment-container > .comment-icon {
    width: 2rem;
    height: auto;
    padding: 0.5rem;
}

.post-card .like-comment-container > .comment-icon:hover {
    color: var(--teal) !important;
    cursor: pointer;
    transition: color 0.2s ease-in-out, background-color 0.5s ease-in-out;
    background-color: var(--slightly-darker-blue);
    border-radius: 11px;
}

.post-card .like-comment-container > .share-icon {
    color: var(--white-teal);
    margin-left: 1rem;
    width: 2rem;
    height: auto;
    padding: 0.4rem;
}

.post-card .like-comment-container > .share-icon:hover {
    color: var(--teal) !important;
    cursor: pointer;
    transition: color 0.2s ease-in-out, background-color 0.5s ease-in-out;
    background-color: var(--slightly-darker-blue);
    border-radius: 11px;
}

.post-card .like-comment-container > .share-icon:active {
    color: var(--orange) !important;
    transform: rotate(10deg);
}

.post-card .comments {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.post-card .load-more-comments-button {
    width: 85%;
    border-radius: 2rem;
    padding: 0.4rem 0rem;
    background-color: var(--slightly-darker-blue);
    outline: 1px solid var(--light-dark-blue);
    color: var(--teal);
    border: none;
    margin-top: 1rem;
    font-size: 0.8rem;
}

.post-card .card-body p, ul, li, h1, h2, h3, h4, h5, blockquote, table, pre, a {
    margin: 0;
}

.post-card .card-body blockquote {
    background-color: var(--slightly-darker-blue);
    border-radius: 11px;
    padding-left: 1rem;
}

.post-card .card-body pre {
    background-color: var(--slightly-darker-blue);
    border-radius: 11px;
    padding: 0.5rem;
}

.post-card .card-body a {
    color: var(--orange);
}

.post-card .card-body ul {
    display: flex;
    flex-direction: column;
}

.post-card .published-ago {
    margin-left: 0.5em;
}
:root {
    --trickyScale: 1;
}

.profileContainer {
    display: flex;
    flex-direction: column;
    padding-left: 2rem;
    padding-right: 2rem;
    height: fit-content;
}

.profileHeader {
    background-color: var(--dark-blue);
    align-self: center;
    width: fit-content;
    min-width: 40em;
    max-width: 50em;
    padding-top: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
    border-radius: 11px;
    display: flex;
    align-items: center;
    box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
}

.profileContainer ul {
    width: 35em;
    align-self: center;
}

/* Tabs */
.profileContainer .nav-tabs {
    --bs-nav-tabs-border-color: transparent;
    --bs-nav-tabs-link-active-bg: var(--dark-blue);
    --bs-nav-tabs-link-active-border-color: transparent;
    --bs-nav-tabs-link-active-color: var(--teal);
    --bs-nav-link-hover-color: var(--orange);
}
.profileContainer .nav-link {
    border-bottom-left-radius: 11px;
    border-bottom-right-radius: 11px; 
    border-top-right-radius: 0;   
    border-top-left-radius: 0;
    background-color: var(--darker-blue);
    color: var(--white-teal);
}
.profileContainer .nav-link:hover {
    outline: transparent;
    border-color: transparent;
}

.profileHeader .profile-pic {
    width: 4rem;
}

.profilePicWithFollowButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 5rem;
    gap: 1rem;
}

#followButton {
    font-size: 0.8rem;
    width: fit-content;
    height: 2em;
    color: var(--darker-blue);
    background-color: var(--teal);
    display: inline-flex;
    align-items: center;
}

.profileInfo {
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    padding-right: 1rem;
    width: fit-content;
    align-content: center;
}

.profileName {
    font-size: 3rem;
    color: var(--white-teal);
    width: 12em;
    margin-left: 1rem;
    margin-bottom: 1rem;
    line-height: 76px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.profileStats {
    display: flex;
    width: 100%;
    gap: 1rem;
    margin-left: 1rem;
}

#statContainer {
    display: flex;
    gap: 0.5rem;
}

span {
    color: var(--teal);
    font-size: 1rem;
}

.infoNum {
    display: inline-flex;
    align-items: center;
    font-size: 1rem;
    text-align: center;
    align-self: center;
    padding: 0.3rem;
    border-radius: 2rem;
    width: fit-content;
    max-width: 3rem;
    height: 1.5rem;
    background-color: var(--orange);
    color: var(--dark-blue);
    overflow: hidden;
    text-overflow: ellipsis;
}

.posts-container-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50vw;
    max-width: 50rem;
    align-self: center;
    gap: 1rem;

}

.posts-container-profile .post-card {
    margin: 0 2rem;
    width: auto !important;
}

.posts-container-profile .load-more-posts-button {
    width: 40rem;
    border-radius: 2rem;
    padding: 0.5rem 0rem;
    background-color: var(--teal-alt);
    border: none;
    margin-top: 1rem;
}

.followers-container-profile, .friends-container-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em 0em 0em;
    width: 65vw;
    max-width: 50rem;
    align-self: center;
    gap: 0.5rem;
}

.host-indicator {
    background: var(--slightly-darker-blue);
    font-size: 0.8rem;
    color: var(--teal);
    padding: 0.4em 1em;
    border-radius: 2rem;
}

.profile-posts-loader {
    margin-top: 2rem;
    align-self: center;
}

.remove-follower-button {
    width: 4rem;
    margin-left: 0.5em;
    border-radius: 2rem;
    border: none;
    background-color: var(--dark-blue);
    outline: 1px solid var(--teal);
    font-size: 0.6rem;
    color: var(--teal);
    line-height: 0.8rem;
    height: 100%;
    padding: 0.5rem 0rem;
}

.follower-card-and-remove {
    max-width: ;
    display: flex;
    align-items: center;
}

.followers-container-profile .userCard {
    max-width: 25rem;
    width: 40vw;
    min-width: 15rem;
    z-index: 1;
}

.topnav {
    position: sticky;
    width: 100%;
    top:0;
    box-shadow: 0px 4px 5px #17191B;
    z-index: 100; 
}

.topnav .profile-pic {
    width: 40px;
    align-self: center;
}

.topnav .profile-pic:hover {
    outline: solid var(--teal) 5px;
    cursor: pointer;
    transition: outline 0.2s ease-in-out;
}

.navbar-expand .navbar-nav .nav-link {
    align-self: center;
}
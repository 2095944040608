.comment-card .profile-pic {
    width: 1.5em;
    min-width: 1.5em;
    flex: 0.05;
}

.comment-card .comment-author {
    width: auto;
    margin-left: 0.5rem;
    font-family: 'Readex Pro Medium';
    color: var(--teal);
}

.comment-card .comment-author-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.comment-card .comment-author-container:hover {
    cursor: pointer;
}

.comment-card {
    background-color: transparent;
    margin: 0.5em 0em;
    --bs-card-border-width: 1px;
    --bs-card-border-color: var(--slightly-darker-blue);
    --bs-card-border-radius: 11px;
    display: grid;
    grid-template-columns: 10fr 1fr;
    align-items: center;
}

.comment-card .card-body {
    display: flex;
    vertical-align: middle;
    align-items: center;
    padding: 0.5rem;
    box-shadow: none;
    width: 100%;
    flex-wrap: wrap;
    height: fit-content;
}

.comment-card .comment-content {
    margin-top: 0.5rem;
    padding: 0rem 1rem;
    width: 100%;
    white-space: pre-line;
    font-family: 'Readex Pro Light';
    font-size: 0.8rem;
}

.comment-card .comment-content > * {
    margin: 0;
}

.comment-like-icon {
    margin: 1em 2em;
    height: 1.3em;
    width: 1.3em;
    padding: 0.2em;
    color: var(--white-teal);
}

.comment-like-icon:hover {
    color: var(--teal) !important;
    cursor: pointer;
    transition: color 0.2s ease-in-out, background-color 0.5s ease-in-out;
    background-color: var(--slightly-darker-blue);
    border-radius: 11px;
}

.comment-card .published-ago {
    font-size: 0.7rem;
    white-space: nowrap;
}
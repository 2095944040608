.main-content-container {
    width: auto;
    height: auto;
    background-color: var(--slightly-darker-blue);
    color: white;
    padding: 2rem;
}

nav.page {
    width: 40em;
}

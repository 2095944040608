
#sidebar {
    position: fixed;
    width: 4rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    z-index: 1000;
}


.nav-pills .nav-link {
    border: 0;
    border-radius: 50rem;
}

body {
    min-height: 100vh;
    min-height: -webkit-fill-available;
    
}

html {
    height: -webkit-fill-available;
}

main {
    display: flex;
    flex-wrap: nowrap;
    height: 100vh;
    height: -webkit-fill-available;
    max-height: 100vh;
    overflow-x: auto;
    overflow-y: hidden;
}

hr {
    color: var(--white-teal);
}
.b-example-divider {
    flex-shrink: 0;
    width: 1.5rem;
    height: 100vh;
    background-color: rgba(0, 0, 0, .1);
    border: solid rgba(0, 0, 0, .15);
    border-width: 1px 0;
    box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.bi {
    vertical-align: -.125em;
    pointer-events: none;
    fill: currentColor;
}

.nav-flush .nav-link {
    border-radius: 0;
}

.scrollarea {
    overflow-y: auto;
}

.fw-semibold { font-weight: 600; }
.lh-tight { line-height: 1.25; }


.signout {
    color: var(--white-teal);
    margin-top: 0.5rem;
    cursor: pointer;

}

.signout:hover {
    color: var(--orange);
}

/* Create Post Button */

#sidebar .side-nav-item {
    align-self: center;
}

.create-post-button {
    color: var(--dark-blue);
    background-color: var(--teal);
    height: 50px;
    width: 50px;
    margin: 0.5em 0em;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    border: 0;
    border-radius: 11px;
}

.create-post-button:hover {
    cursor: pointer;
}

.side-nav-link {
    width: 50px;
    height: 50px;
    padding: 0.5rem 0.5rem;
    margin: 0.5rem 0.5rem;
    border-radius: 11px;
}

.side-nav-link:hover {
    cursor: pointer;
    background: var(--slightly-darker-blue);
    transition: background-color 0.5s ease-in-out;
    border-radius: 11px;
}

.side-nav-link:hover svg {
    color: var(--teal) !important;
    transition: color 0.2s ease-in-out;
    
}
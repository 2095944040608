.explore-title-container {
    padding-bottom: 1rem;
}

.search-bar-explore {
    padding: 0.5em 3em;
    width: 20em;
    text-align: center;
    background-color: var(--slightly-darker-blue);
    color: var(--white-teal);
    border: none;
    border-bottom: solid 1px var(--orange);
    position: relative;
    margin-left: -2em;
    z-index: 2;
    border-radius: 11px;
}

.FaSearch {
    color: var(--teal);
    margin-left: -em;
    height: 1em;
    width: 1em;
    z-index: 3;
}

.search-col {
    display: flex;
    align-items: center;
}

.authors-explore-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 13.3rem;
    height: auto;
    overflow-y: scroll;
    scroll-behavior: smooth;
    position: absolute;
    top: 9rem;
    z-index: 2;
    width: 20rem;
    transform: translateX(-1rem);
    background-color: var(--slightly-darker-blue);
    padding: 0.5rem;
    border-bottom-left-radius: 11px;
    border-bottom-right-radius: 11px;
    gap: 0.2rem;
}

.authors-explore-container .userCard {
    width: 100%;
}

.search-bar-explore:focus {
    outline: none !important;
    background: var(--dark-blue);
}

.custom {
    width: 20em;
}

.searchResult {
    position: relative;
}

.public-post {
    border-style: solid;
    border-width: medium;
}

.all-posts-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.public-posts-and-remote-container {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 1em;
}

.remote-authors-container .userCard {
    width: 100%;
    margin-top: 0.5em;
}

.remote-authors-container .dropdown-toggle {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: var(--dark-blue);
    color: var(--teal);
    --bs-btn-border-radius: 2rem;
    margin-top: 0.3rem;
}

.remote-authors-container .dropdown-toggle::after {
    margin-left: auto;
}

.remote-authors-container .dropdown-menu {
    width: 100%;
    z-index: 2;
    background-color: var(--slightly-darker-blue);
    box-shadow: rgb(0 0 0 / 50%) 1px 1px 10px;
    outline: 2px solid var(--light-dark-blue);
    --bs-dropdown-link-hover-bg: var(--darker-blue);
    --bs-dropdown-link-hover-color: var(--teal);
    --bs-dropdown-link-active-bg: var(--orange);
    --bs-dropdown-link-active-color: var(--dark-blue);
    --bs-dropdown-link-color: var(--white-teal);
}

.public-posts-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 4rem;
}

.public-posts-loader {
    align-self: center;
}

.remote-authors-content {
    display: flex;
    flex-direction: column;
}

.remote-authors-loader {
    margin-top: 2rem;
    align-self: center;
}

.pagination-container {
    align-self: center;
    display: flex;
    gap: 0.5rem;
    position: absolute;
    bottom: 1rem;
}

.pagination-next, .pagination-prev {
    background-color: var(--dark-blue);
    width: 2.5rem;
    height: 2.5rem;
    padding: 0.8rem;
    color: var(--teal);
    border-radius: 2rem;
}

.pagination-next:hover, .pagination-prev:hover {
    background-color: var(--slightly-darker-blue);
    color: var(--teal-alt);
    cursor: pointer;
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
}


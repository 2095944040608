.userCard {
    width: 50%;
    height: fit-content;
    --bs-card-bg: none;
}

.userCard:hover {
    cursor: pointer;
}

.userCard .card-body {
    margin-bottom: 0;
    color: var(--white-teal);
    background-color: var(--dark-blue);
    box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
    border: transparent;
    border-radius: 11px;
    width: auto;
}

.userCard .profilePicCard {
    width: 1em;
    height: auto;
    aspect-ratio: 1/1;
    border-radius: 100%;
    outline: solid var(--orange) 2px;
    text-align: center;
    display: flex;
    color: var(--white-teal);
    overflow:hidden;
    place-content: center;
}

.userCard .text {
    color: var(--white-teal);
    font-size: 0.5em;
    display: inline-flex;
    align-items: center;
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.userCard .card-title {
    display: flex;
    gap: 0.5em;
    align-self: center;
    margin-bottom: 0;
}

.no-author {
    margin-top: auto;
    margin-bottom: auto;
    color: var(--white-teal);
    background-color: var(--dark-blue);
    box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
    border: transparent;
    border-radius: 11px;
    width: auto;
    height: 50px;
    padding-top: 1%;
    padding-left: 3%;
}